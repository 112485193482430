<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="$t('view.deviceevent.detailtitle')+': '+this.name">
          <div class="row">
            <div class="col-md-3">
              <button type="button" v-on:click="backPage" class="btn btn-primary btn-sm">Back</button>
            </div>
            <div class="col-md-6">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Version APK</th>
                    <th scope="col">Serial</th>
                    <th scope="col">Modelo</th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{this.name}}</td>
                    <td>{{this.softwareversion}}</td>
                    <td>{{this.serial}}</td>
                    <td>{{this.model}}</td>
                  </tr>
                 
                
                </tbody>
              </table>
            </div>
          </div>
        </vuestic-widget>
      </div>
      <div class="col-md-12">
        <vuestic-tabs class="tabs" :names="['Conexión', 'Recargas','Ventas','Devoluciones']">
          <div slot="Conexión" class="d-flex justify-content-center">
               <div class="col-md-12">
              <TableDeviceConnection></TableDeviceConnection>
            </div>
          </div>
          <div slot="Recargas" class="d-flex justify-content-center">
               <div class="col-md-12">
              <TableTopupDevice></TableTopupDevice>
            </div>
          </div>
          <div slot="Ventas" class="d-flex justify-content-center">
            <div class="col-md-12">
              <TableSalesDevice></TableSalesDevice>
            </div>
          </div>
          <div slot="Devoluciones" class="d-flex justify-content-center">
                  <div class="col-md-12">
              <TableRefundDevice></TableRefundDevice>
            </div>
          </div>
        </vuestic-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { SpringSpinner } from "epic-spinners";
import TableSalesDevice from "../tables/TableDevice/TableSalesDevice.vue";
import TableDeviceConnection from "../tables/TableDevice/TableDeviceConnection.vue";
import TableRefundDevice from "../tables/TableDevice/TableRefundsDevice.vue";
import TableTopupDevice from "../tables/TableDevice/TableTopupDevice.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Table",
  components: {
    SpringSpinner,
    TableSalesDevice,
    TableDeviceConnection,
    TableRefundDevice,
    TableTopupDevice
  },
  data() {
    return {
      name: "",
      mac: "",
      serial: "",
      model: "",
      softwareversion: "",
      active: 1
    };
  },
  mounted() {
    var id = this.$route.params.iddevice;
    if (id !== null) {
      this.loadDevice(id)
        .then(data => {
          this.name = data.name;
          this.mac = data.mac;
          this.serial = data.serial;
          this.model = data.model;
          this.softwareversion = data.softwareversion;
          this.active = data.active;
        })
        .catch(data => {
          this.addToastMessage({
            text: "Ocurrio un error",
            type: "success"
          });
        });
    } else {
      this.addToastMessage({
        text: "Ocurrio un error",
        type: "success"
      });
    }
  },
  computed: {
    ...mapState({})
  },
  methods: {
    ...mapActions(["loadDevice", "updateDevice", "addToastMessage"]),
    back() {
      // this.$router.go(-1);
      this.$router.push("/");
    },
      backPage: function () {
            this.$router.go(-1)
        },
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
